<template>
  <div class="user">
    <div class="title">
      <h1>用户协议</h1>
    </div>
    <p>1、导言</p>
    <p>
      本《用户协议》（以下简称“本协议”）是您与{{
        company
      }}（以下简称“公司”）之间就您注册、登录、使用（以下统称“使用”）{{
        gameName
      }}游戏软件 （以下简称“本游戏”）及相关服务所订立的协议。
      为了更好地为您提供服务，请您在开始使用本游戏软件及相关服务之前，务必认真阅读并充分理解本协议，
      特别是涉及免除或者限制责任的条款、权利许可和信息使用的条款、法律适用和争议解决条款等。
      <span class="bold"
        >其中，免除或者限制责任条款等重要内容将以加粗形式提示您注意，您应重点阅读。</span
      >
      如您未满18周岁，请您在法定监护人陪同下仔细阅读并充分理解本协议，特别注意未成年人使用条款，并在征得法定监护人的同意后使用本游戏软件及相关服务。
      如您为未成年人法定监护人，希望您合理设定孩子娱乐时间，培养孩子健康游戏习惯。
    </p>
    <p class="bold">
      如您不同意本协议，这将导致我们无法为您提供完整的产品和服务，您也可以选择停止使用。
      如您自主选择同意或使用本游戏软件及相关服务，则视为您已充分理解本协议，并同意作为本协议的一方当事人接受本协议以及其他与本游戏软件及相关服务相关的协议和规则（包括但不限于《隐私政策》）的约束。
    </p>
    <p>2、定义</p>
    <p>
      2.1
      本游戏软件：指公司提供的游戏软件（包括任何可在移动智能设备及/或电脑终端上，以客户端/微端或网页等形式进行操作的适配版本），
      以及与该等游戏软件相关的更新、升级、补丁、资料片等。并可能包括相关的网络服务器、网站（包括但不限于该游戏之官方网站或官方论坛）、电子媒体、印刷材料或电子文档等。
    </p>
    <p>
      2.2
      本游戏服务：指公司向您提供的、与前述本游戏软件相关的各项在线运营服务，并包括本游戏软件本身。
    </p>
    <p>
      2.3
      游戏规则：指公司不时发布并修订的关于本游戏软件的用户规范、游戏公告及通知、指引、说明等内容。
    </p>
    <p>
      2.4
      您：又称“玩家”或“用户”，指被授权安装、使用本游戏软件或接受本游戏相关服务的自然人。
    </p>
    <p>
      2.5
      游戏数据：指您在使用本游戏软件过程中产生并存储于本游戏软件服务器中的各种数据信息，包括但不限于游戏角色数据信息、游戏日志、登记及虚拟物品数据信息等。
    </p>
    <p>3、关于“账号”</p>
    <p>
      3.1
      如果您需要使用和享受本游戏软件及相关服务，则您应按相关规定，登录实名注册系统并进行实名注册。
    </p>
    <p>
      您进行实名注册时，应提供有关您本人真实、合法、准确、有效的身份信息及其他相关信息，且不得以他人身份资料进行实名注册。
      否则，公司有权终止为您提供本游戏软件及相关服务，并有权对您的游戏账号采取包括但不限于警告、限制或禁止使用游戏账号全部或部分功能、
      删除游戏账号及游戏数据、删除相关信息、游戏账号封禁（以下简称“封号”）直至注销的处理措施（上述处理措施以下简称“处罚”），因此造成的一切后果由您自行承担。
    </p>
    <p>
      若您不进行实名注册的，或您提供的注册信息不完整的，则您可能无法使用本游戏软件及相关服务或在使用过程中会受到相应限制。
    </p>
    <p>
      除自行注册游戏账号外，您也可授权使用“今日头条”、“抖音”等第三方账号登录使用本游戏软件及相关服务。
      当您以前述已有账号登录使用的，应保证相应账号已进行实名注册登记，并同样适用本协议中的相关条款。
    </p>
    <p class="bold">
      3.2
      您进一步知悉并同意，如果您选择通过游客模式快速登录本游戏软件的，请您登录后及时绑定用户账号，
      否则一旦您卸载或重装本游戏软件，或您的手机、电脑等终端设备损坏导致本游戏软件无法正常启动的，您在该游客模式下所有游戏相关数据将无法查询或恢复。如因此造成您任何损失的，均由您自行承担。
    </p>

    <p>
      3.3
      您充分理解并同意：公司会按照国家相关要求将您的实名注册信息运用于防沉迷系统之中，
      即公司可能会根据您的实名注册信息判断您是否年满18周岁，从而决定是否对您的游戏账号予以防沉迷限制。
    </p>
    <p>
      3.4
      您有责任维护个人账号、密码的安全性与保密性，并对您以注册账号名义所从事的活动承担全部法律责任，
      包括但不限于您在本游戏软件及相关服务上进行的操作行为可能引起的一切法律责任。您应高度重视对账号与密码的保密，在任何情况下不向他人透露账号及密码。若发现他人未经许可使用您的账号或发生其他任何安全漏洞问题时，您应当立即通知公司。
    </p>
    <p class="bold">
      3.5
      在丢失账号或遗忘密码时，您可遵照公司的申诉途径及时申诉请求找回账号或密码。您理解并认可，公司的密码找回机制仅需要识别申诉单上所填资料与系统记录资料具有一致性，而无法识别申诉人是否系真正账号有权使用者。公司特别提醒您应妥善保管您的账号和密码。当您使用完毕后，应安全退出。如因您保管不当等自身原因或其他不可抗因素导致遭受盗号或密码丢失，您将自行承担相应责任。
    </p>
    <p class="bold">
      3.6
      您充分理解并同意，如果您3年内没有登录本游戏，公司有权采取措施删除该账号及该账号下的在游戏数据库中的任何记录。
    </p>
    <p>4、本游戏软件及相关服务</p>
    <p>
      4.1
      您使用本游戏软件及相关服务，可以通过预装、公司已授权的第三方下载等方式获取本游戏客户端应用程序。若您并非从公司或经公司授权的第三方获取本软件的，公司无法保证非官方版本的本游戏软件能够正常使用，您因此遭受的损失与公司无关。
    </p>
    <p>
      4.2
      公司可能为不同的终端设备开发不同的应用程序软件版本，您应当根据实际设备状况获取、下载、安装合适的版本。
      如您不再使用本游戏软件及相关服务，您也可自行卸载相应的应用程序软件。
    </p>
    <p>
      4.3
      为更好的提升用户体验及服务，公司将不定期提供本游戏软件及相关服务的更新或改变（包括但不限于软件修改、升级、功能强化、开发新服务、软件替换等），您可根据需要自行选择是否更新相应的版本。
    </p>
    <p>
      为保证本游戏软件及相关服务安全、提升用户服务，在本游戏软件及相关服务的部分或全部更新后，公司将在可行的情况下以妥当的方式（包括但不限于系统提示、公告、站内信等）提示您，您有权选择接受更新后的版本；如您选择不作更新，本游戏软件及相关服务的部分功能将受到限制或不能正常使用。
    </p>

    <p class="bold">
      4.4
      除非得到公司事先书面授权，您不得以任何形式对本游戏进行录制、直播或向他人传播游戏内容，包括但不限于不得利用任何第三方软件进行网络直播、传播等。
    </p>
    <p>
      4.5
      您理解，使用本游戏软件及相关服务需自行准备与软件及相关服务有关的终端设备（如电脑、手机等），一旦您在终端设备中打开本游戏软件，即视为您使用本游戏。为充分实现本游戏的全部功能，您可能需要将终端设备联网，您理解由您承担所需要的费用（如流量费、上网费等）。
    </p>
    <p>
      4.6
      在您同意接受本协议全部条款的前提下，公司同意许可您一项个人的、不可转让和不可转授权的、非独占的、
      非商业使用性质的、可撤销的、可变更的合法使用本游戏软件及相关服务的权利。包括：
    </p>
    <p>
      （1）接收、下载、安装、启动、升级、登录、显示、运行和/或截屏本游戏；
    </p>
    <p>
      （2）创建游戏角色，开设游戏房间、使用聊天功能、设置角色昵称、设置账户昵称、查阅游戏规则、
      用户个人资料、游戏对局结果，在游戏中购买、使用游戏道具、游戏装备、游戏币等，使用社交分享功能；
    </p>
    <p>（3）使用本游戏支持并允许的其他功能。</p>
    <p>
      本协议未明示授权的其他一切权利仍由公司保留，您在行使该些权利前须另行获得公司的书面许可，同时公司如未行使前述任何权利，并不构成对该权利的放弃。
    </p>
    <p class="bold">
      4.7 您充分理解并同意：游戏道具、游戏装备、游戏币等是本游戏软件的组成部分，
      公司许可您依本协议而获得其使用权。您购买、使用游戏道具、游戏装备、游戏币等应遵循相关法律法规的规定，
      遵循本协议、游戏具体规则的要求。同时，游戏道具、游戏装备、游戏币等可能受到一定有效期限的限制，即使您在规定的有效期内未使用，除不可抗力或可归责于公司的原因外，一旦有效期届满，将会自动失效。
    </p>
    <p class="bold">
      4.8
      您充分理解并同意：为更好地向用户提供游戏服务，公司有权对游戏中的任何内容或构成元素等做出调整、更新
      或优化（包括但不限于您购买或正在使用的角色、游戏装备及其他游戏道具等）。且如公司做出相应调整、更新或优化的，您同意不会因此追究公司的任何法律责任。
    </p>
    <p class="bold">
      4.9
      您充分理解并同意：为保障您游戏账号安全，为营造公平、健康及安全的游戏环境，在您使用本游戏服务的过程中，在不违反相关法律法规规定的情况下，
      公司可以通过技术手段了解您终端设备的随机存储内存以及与本游戏同时运行的相关程序。一经发现有任何未经授权的、危害本游戏服务正常运营的相关程序，公司可以收集所有与此有关的信息并采取合理措施予以打击。
    </p>
    <p class="bold">
      4.10 公司向用户提供游戏服务本身属于商业行为，
      用户有权自主决定是否根据公司自行确定的收费项目（包括但不限于购买游戏内的虚拟道具的使用权以及接受其他增值服务等各类收费项目）及收费标准支付相应的费用，以获得相应的游戏服务。如您不按相应标准支付相应费用的，您将无法获得相应的游戏服务。
    </p>
    <p class="bold">
      您知悉并同意：收费项目或收费标准的改变、调整是一种正常的商业行为，您不得因为收费项目或收费标准的改变、调整而要求公司进行赔偿或补偿。
    </p>
    <p class="bold">
      4.11
      本游戏可能因游戏软件BUG、版本更新缺陷、第三方病毒攻击或其他因素导致您的游戏角色、游戏道具、游戏装备及游戏币等账号数据发生异常。在数据异常的原因未得到查明前，公司有权暂时冻结该游戏账号；若查明数据异常为非正常游戏行为所致，公司有权恢复游戏账号数据至异常发生前的原始状态（包括向第三方追回被转移数据），且公司无须向您承担任何责任。
    </p>
    <p class="bold">
      4.12
      公司未授权您从任何第三方通过购买、接受赠与或者其他的方式获得游戏账号、游戏道具、游戏装备、游戏币等，公司不对第三方交易的行为负责，并且不受理因任何第三方交易发生纠纷而带来的投诉。
    </p>
    <p>
      4.12
      公司未授权您从任何第三方通过购买、接受赠与或者其他的方式获得游戏账号、游戏道具、游戏装备、游戏币等，公司不对第三方交易的行为负责，并且不受理因任何第三方交易发生纠纷而带来的投诉。
    </p>
    <p class="bold">
      4.14
      公司自行决定终止运营游戏时或游戏因其他任何原因终止运营时，公司会按照相关法律规定处理游戏终止运营相关事宜，以保障您的合法权益。
    </p>
    <p>5、用户个人信息保护</p>
    <p>
      公司与您一同致力于您个人信息（即能够独立或与其他信息结合后识别用户身份的信息）的保护，保护用户个人信息
      是公司的基本原则之一。
      在使用本游戏软件及相关服务的过程中，您可能需要提供您的个人信息（包括但不限于姓名、电话号码、位置信息等），以便公司向您提供更好的服务和相应的技术支持。公司将运用加密技术、匿名化处理等其他与本游戏软件及相关服务相匹配的技术措施及其他安全措施保护您的个人信息，更多关于用户个人信息保护的内容
      请参见《隐私政策》。
    </p>
    <p>6、 用户行为规范</p>
    <p>
      6.1
      您应当对您使用本游戏软件及相关服务的行为负责，除非法律允许或者经公司事先书面许可，您使用本游戏软件及相关服务不得具有下列行为：
    </p>
    <p>
      6.1.1
      使用未经公司授权或许可的任何插件、外挂、系统或第三方工具对本游戏软件及相关服务的正常运行进行干扰、破坏、修改或施加其他影响。
    </p>
    <p>
      6.1.2
      利用或针对本游戏软件及相关服务进行任何危害计算机网络安全或侵犯本游戏软件合法权益的行为，包括但不限于：
    </p>
    <p>
      （1）非法侵入他人网络、干扰他人网络正常功能、窃取网络数据等危害网络安全的活动；
    </p>
    <p>
      （2）提供专门用于从事侵入网络、干扰网络正常功能及防护措施、窃取网络数据等危害网络安全活动的程序、工具；
    </p>
    <p>
      （3）未经允许进入公众计算机网络或者他人计算机系统并删除、修改、增加存储信息；
    </p>
    <p>
      （4）未经许可，企图探查、扫描、测试本游戏系统或网络的弱点或其它实施破坏网络安全的行为；
    </p>
    <p>
      （5）企图干涉、破坏本游戏系统或网站的正常运行，故意传播恶意程序或病毒以及其他破坏干扰正常网络信息服务的行为；
    </p>
    <p>
      （6）建立有关本游戏的镜像站点，或者进行网页（络）快照，或者利用架设服务器等方式，为他人提供与本游戏服务完全相同或者类似的服务；
    </p>
    <p>
      （7）对本游戏软件及相关服务进行复制、翻录、反向工程、反向汇编、编译、传播和陈列本游戏软件的程序、使用手册和其他图文音像资料的全部或部分内容或者以其他方式尝试发现本软件的源代码；
    </p>
    <p>
      （8）对游戏中公司拥有知识产权的内容进行使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版、建立镜像站点等；
    </p>
    <p>
      （9）公开展示和以各种形式、在各种平台（包括但不限于网络直播平台、网站、APP等）播放本游戏及相关服务的全部或部分内容；
    </p>
    <p>
      （10）出租、销售本游戏软件或者利用本游戏软件从事任何营利行为；
    </p>
    <p>
      （11）修改或遮盖本游戏软件程序、图像、动画、包装和手册等内容上的服务名称、公司标志、商标或版权信息、其他知识产权声明等内容；
    </p>
    <p>
      （12）将本游戏的任意部分分离出来单独使用，或者进行其他的不符合本协议的使用；
    </p>
    <p>
      （13）对游戏软件或者软件运行过程中释放到任何终端内存中的数据、软件运行过程中客户端与服务器端的交互数据，以及软件运行所必需的系统数据，进行复制、修改、增加、删除、挂接运行或创作任何衍生作品，
      形式包括但不限于使用插件、外挂或非经合法授权的第三方工具/服务接入软件和相关系统；
    </p>
    <p>
      （14）通过非公司开发、授权的第三方软件、插件、外挂、系统，使用本游戏软件及相关服务，或制作、发布、传播非公司开发、授权的第三方软件、插件、外挂、系统；
    </p>
    <p>
      （15）其他违反商标法、著作权法、计算机软件保护条例等相关法律法规、本协议、公司的相关规则及侵犯他人合法权益的其他行为。
    </p>
    <p class="bold">
      6.2您在使用本游戏软件及相关服务时须遵守法律法规，不得利用本游戏软件从事违法违规行为，包括但不限于以下行为：
    </p>
    <p>（1）反对宪法确定的基本原则的；</p>
    <p>（2）危害国家安全，泄露国家秘密的；</p>
    <p>
      （3）颠覆国家政权，推翻社会主义制度、煽动分裂国家、破坏国家统一的；
    </p>
    <p>（4）损害国家荣誉和利益的；</p>
    <p>（5）宣扬恐怖主义、极端主义的；</p>
    <p>（6）宣扬民族仇恨、民族歧视，破坏民族团结的；</p>
    <p>（7）煽动地域歧视、地域仇恨的；</p>
    <p>（8）破坏国家宗教政策，宣扬邪教和迷信的；</p>
    <p>
      （9）编造、散布谣言、虚假信息，扰乱经济秩序和社会秩序、破坏社会稳定的；
    </p>
    <p>
      （10）散布、传播暴力、淫秽、色情、赌博、凶杀、恐怖或者教唆犯罪的；
    </p>
    <p>
      （11）侵害未成年人合法权益或者损害未成年人身心健康的；
    </p>
    <p>
      （12）包含恐怖、暴力血腥、高危险性、危害表演者自身或他人身心健康内容的；
    </p>
    <p>
      （13）危害网络安全、利用网络从事危害国家安全、荣誉和利益的；
    </p>
    <p>（14）侮辱或者诽谤他人，侵害他人合法权益的；</p>
    <p>（15）涉及他人隐私、个人信息或资料的；</p>
    <p>
      （16）侵犯他人隐私权、名誉权、肖像权、知识产权等合法权益内容的；
    </p>
    <p>
      （17）其他违反法律法规、政策及公序良俗、干扰本游戏软件正常运营或侵犯其他用户或第三方合法权益内容的其他信息。
    </p>
    <p class="bold">
      6.3如果公司有理由认为您的行为违反或可能违反上述6.1条或6.2条约定的，公司可独立进行判断并处理，
      且有权在不事先通知的情况下终止向您提供服务，并追究您的相关法律责任。
    </p>
    <p class="bold">
      6.4
      您在使用本游戏软件及相关服务过程中有如下任一行为的，公司有权视情节严重程度，依据本协议及相关游戏规则的规定，对您做出暂时或永久性地禁止登录（即封号）、删除游戏账号及游戏数据、删除相关信息等处理措施，情节严重的将移交有关行政管理机关给予行政处罚，或者追究您的刑事责任：
    </p>
    <p class="bold">
      （1）以任何方式破坏或影响本游戏软件及相关服务的正常进行；
    </p>
    <p class="bold">
      （2）在本游戏中使用非法或不当词语、字符等，包括用于角色命名；
    </p>
    <p class="bold">（3）各种非法外挂行为；</p>
    <p class="bold">
      （4）使用游戏同步器（具体包括但不限于使用键盘、鼠标等硬件同步器，以便可以使用一套键盘、鼠标，同时、同步控制多台电脑进行游戏的行为），以及使用其他各种可以让您在游戏效率或收益数据上表现异常的硬件（以下统称“作弊硬件”）；
    </p>
    <p class="bold">（5）盗取他人游戏账号、游戏物品；</p>
    <p class="bold">（6）私自进行游戏账号交易；</p>
    <p class="bold">
      （7）私自进行游戏虚拟货币、游戏装备、游戏币及其他游戏道具等交易；
    </p>
    <p class="bold">（8）违反本协议任何约定的；</p>
    <p class="bold">
      （9）其他在行业内被广泛认可的不当行为，无论是否已经被本协议或游戏规则明确列明。
    </p>
    <p class="bold">
      您知悉并同意，由于外挂具有隐蔽性或用完后即消失等特点，公司有权根据您的游戏数据和表现异常判断您有无使用非法外挂等行为。
    </p>
    <p class="bold">
      6.5
      您知悉并同意，如公司依据本协议对您的游戏账号采取封号处理措施的，具体封号期间由公司根据您违规行为情节而定。
    </p>
    <p class="bold">
      您知悉并同意：
      （1）在封号期间，您游戏账号中的游戏虚拟货币、游戏装备、游戏币及其他游戏道具可能都将无法使用；
      （2）如前述游戏虚拟货币、游戏装备、游戏币及其他游戏道具存在一定有效期，该有效期可能会在封号期间过期，您游戏账号解封后，您将无法使用该等已过期的游戏虚拟货币、游戏装备、游戏币及其他游戏道具。
      据此，您也同意不会因发生前述第（1）和（或）第（2）点规定的情形而追究公司任何法律责任。
    </p>
    <p>7、违约处理</p>
    <p class="bold">
      7.1
      针对您违反本协议或其他服务条款的行为，公司有权独立判断并视情况采取相应的措施进行处理，该措施包括但不限于：不经通知随时对相关内容进行删除，并视行为情节对违规游戏账号处以包括但不限于警告、限制或禁止使用全部或部分功能、游戏账号封禁直至注销的处罚，并公告处理结果，要求您赔偿因您从事违约行为而给公司造成的损失等。
    </p>
    <p class="bold">
      7.2
      因您违反本协议或其他服务条款规定，引起第三方投诉或诉讼索赔的，您应当自行处理并承担全部可能由此引起的法律责任。因您的违法、违约或侵权等行为导致公司及其关联公司向任何第三方赔偿或遭受国家机关处罚的，您还应足额赔偿公司及其关联公司因此遭受的全部损失。
    </p>
    <p class="bold">
      7.3 公司尊重并保护用户及他人的知识产权、名誉权、姓名权、隐私权等合法权益。
      您保证，在使用本游戏软件及相关服务时上传的文字、图片、视频、音频、链接等不侵犯任何第三方的知识产权、名誉权、姓名权、隐私权等权利及合法权益。
      否则，公司有权在收到权利方或者相关方通知的情况下移除该涉嫌侵权内容。针对第三方提出的全部权利主张，您应自行处理并承担全部可能由此引起的法律责任；如因您的侵权行为导致公司及其关联公司遭受损失的（包括经济、商誉等损失），您还应足额赔偿公司及其关联公司遭受的全部损失。
    </p>
    <p>8、知识产权</p>
    <p>
      8.1
      公司在本游戏软件及相关服务中提供的内容（包括但不限于软件、技术、程序、网页、文字、图片、图像、音频、视频、图表、版面设计、电子文档等）的知识产权属于公司所有。
      公司提供本服务时所依托的软件的著作权、专利权及其他知识产权均归公司所有。未经公司许可
      ，任何人不得擅自使用（包括但不限于通过任何机器人、蜘蛛等程序或设备监视、复制、传播、展示、镜像、上载、下载）本游戏软件及相关服务中的内容。
    </p>
    <p>
      8.2
      公司为本游戏开发、运营提供技术支持，并对本游戏软件及相关服务的开发和运营等过程中产生的所有数据和信息等享有法律法规允许范围内的全部权利。
    </p>
    <p class="bold">
      8.3
      尽管本协议有其他规定，您在使用本游戏软件及相关服务中产生的游戏数据的所有权和知识产权归公司所有，公司有权保存、处置该游戏数据。其中，公司对用户购买游戏虚拟货币的购买记录的保存期限将遵守有关法律规定。对其他游戏数据的保存期限由公司自行决定，但国家法律法规另有规定的从其规定。
    </p>
    <p>
      8.4
      本游戏可能涉及第三方知识产权，而该等第三方对您基于本协议在本游戏中使用该等知识产权有要求的，公司将以适当方式向您告知该要求，您应当一并遵守。
    </p>
    <p class="bold">9、未成年人使用条款</p>
    <p>
      9.1
      若用户是未满18周岁的未成年人，应在监护人监护、指导并获得监护人同意情况下认真阅读本协议后，方可使用本游戏软件及相关服务。
    </p>
    <p>
      9.2
      未成年用户理解如因您违反法律法规、本协议内容，则您及您的监护人应依照法律规定承担因此而可能引起的全部法律责任。
    </p>
    <p>
      公司将按照相关法律法规和本协议的规定，采取切实有效的措施保护未成年人在使用本游戏服务过程中的合法权益，
      包括可能采取技术措施、禁止未成年人接触不适宜的游戏或者游戏功能、限制未成年人的游戏时间、预防未成年人沉迷网络。
      其他玩家在使用本游戏服务的过程中应避免发布、产生任何有损未成年人身心健康的内容，共同营造健康游戏环境。
    </p>
    <p class="bold">
      9.4
      未成年人用户特别提示：如果您未满18周岁的，为保障您的合法权益，公司有权依据国家有关法律法规及相关政策规定、本协议约定采取以下一种或多种措施：
    </p>
    <p class="bold">
      （1）将与您游戏相关的信息（包括但不限于您游戏账号的登录信息等）提供给您的法定监护人，使得您法定监护人可及时或同步了解您游戏情况；
    </p>
    <p class="bold">
      （2）采取技术措施屏蔽某些游戏或游戏的某些功能，或限定您游戏时间或游戏时长；
    </p>
    <p class="bold">
      （3）注销或删除您游戏账号及游戏数据等相关信息；
    </p>
    <p class="bold">
      （4）您法定监护人要求采取的，或公司认为可采取的其他合理措施，以限制或禁止您使用本游戏。
    </p>
    <p>10、其他</p>
    <p class="bold">
      10.1
      本协议的成立、生效、履行、解释及争议的解决均应适用中华人民共和国大陆地区法律。
      若本协议之任何规定因与中华人民共和国大陆地区的法律抵触而无效或不可执行，则这些条款将尽可能按照接近本协议原条文意旨重新解析，
      且本协议其它规定仍应具有完整的效力及效果。
    </p>
    <p class="bold">
      10.2 本协议的签署地点为中华人民共和国北京市海淀区。
      若您因本协议与公司发生任何争议，双方应尽量友好协商解决；如协商不成的，您同意应将相关争议提交至北京市海淀区人民法院诉讼解决。
    </p>
    <p class="bold">
      10.3
      为给您提供更好的服务或国家法律法规、政策调整，本游戏软件及相关服务将不时更新与变化，我们会适时对本协议进行修订，这些修订构成本协议的一部分。
      本协议更新后，我们会在本游戏发出更新版本，并在更新后的条款生效前通过官方网站公告或其他适当的方式提醒您更新的内容，
      以便您及时了解本协议的最新版本，您也可以在网站首页或软件设置页面查阅最新版本的协议条款。
      如您继续使用本游戏软件及相关服务，表示同意接受修订后的本协议的内容。
    </p>
    <p>
      10.4
      本协议中的标题仅为方便及阅读而设，并不影响本协议中任何规定的含义或解释。
    </p>
    <p>
      10.5
      您和公司均是独立的主体，在任何情况下本协议不构成公司对用户的任何形式的明示或暗示担保或条件，双方之间亦不构成代理、合伙、合营或雇佣关系。
    </p>
    <p>
      10.6 根据国家新闻出版总署关于健康游戏的忠告，我们提醒您：
      <span class="bold">
        抵制不良游戏，拒绝盗版游戏；注意自我保护，谨防受骗上当；适度游戏益脑，沉迷游戏伤身；合理安排时间，享受健康生活。
      </span>
    </p>
  </div>
</template>

<script>
export default {
  name: 'user',
  data () {
    return {
      Date1: '',
      Date2: '',
      gameName: '',
      company: '',
      Date3: '',
      main: ''
    }
  },
  mounted () {
    this.Date1 = this.$utils.getUrlKey('Date1')
    this.Date2 = this.$utils.getUrlKey('Date2')
    this.gameName = this.$utils.getUrlKey('gameName')
    this.company = this.$utils.getUrlKey('company')
    this.Date3 = this.$utils.getUrlKey('Date3')
    this.main = this.$utils.getUrlKey('main')
  }
}
</script>

<style lang="less" scoped>
.user {
  .title {
    text-align: center;
  }
  .bold {
    font-weight: bold;
  }
}
</style>
